.bm-item {
  display: inline-block;

  /* sidebar item styling */
  text-decoration: none;
  color: #fff;
  transition: color 0.2s;
}

.bm-item:hover {
  color: white;
}

.bm-burger-button {
  position: absolute;
  width: 26px;
  height: 20px;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
}

.bm-cross-button {
  span {
    top: 0px !important;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #fff;
  opacity: 1 !important;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 50px !important;
  width: 50px !important;
  top: 0 !important;
  right: 0 !important;
  span {
    top: 12px !important;
    right: 12px !important;
    .bm-cross {
      top: 0 !important;
    }
  }
}

/* Color/shape of close button cross */
.bm-cross {
  height: 24px !important;
  background-color: #fff;
}

/* General sidebar styles */
.bm-menu {
  @apply p-0 font-helvetica;
}

.bm-menu::-webkit-scrollbar {
  display: none !important;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  @apply left-0 right-0;
}
/* Disable click while loading */
.is-loading .bm-burger-button button {
  pointer-events: none;
}
.is-loading .bm-burger-button {
  @apply opacity-60;
}
.menu-label {
  @apply w-full px-2 mt-2.5 rounded cursor-pointer;
  display: inline-flex;
  background-color: rgba(196, 196, 196, 0.2);
  font-size: 16px;
  line-height: 40px;
  color: #fff5c2;
}
.selected-option {
  @apply font-bold pl-6 items-center bg-zynga-red fixed w-full cursor-pointer;
  display: flex !important;
  font-size: 22px;
  line-height: 26px;
  height: 3rem;
}
.sidebar-container {
  @apply h-full overflow-auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
  background-color: #7a1d1e !important;

  .menu-app-wrapper {
    @apply border-b py-4 flex items-center cursor-pointer;
    border-color: rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  .app-icon-wrapper {
    @apply w-16 h-16 border;
    border-color: rgba(0, 0, 0, 0.5);
    border-radius: 10px;
  }
  .app-name {
    @apply font-normal text-white pl-4;
    font-family: 'Helvetica Neue';
    font-size: 18px;
    line-height: 20px;
  }
  .selected-menu-container {
    @apply py-4;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  }
  .selected-menu-container:last-child {
    border-bottom: none;
  }
  .selected-menu-label {
    @apply font-bold;
    font-size: 16px;
    line-height: 20px;
  }
  .selected-menu-text {
    @apply pt-2;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.7);
  }
}
.sidebar-container::-webkit-scrollbar {
  display: none;
}
.faq-container {
  @apply px-6 pt-16;
}
.sidebar-footer {
  @apply text-center font-normal pt-6 pb-4 sticky;
  top: 100vh;
  color: #fff;
  font-size: 12px;
  line-height: 14px;
}

@media (max-width: 360px) {
  .bm-menu-wrap {
    width: 100% !important;
  }
}

.menu-app-wrapper.poker {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/poker-menu-logo.png') no-repeat;
    background-size: cover;
  }
}
.menu-app-wrapper.e-and-p {
  .app-icon-wrapper {
    background: url('https://zyngastoreassets.smallgiantgames.com/app_icon_empires.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.hir {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/hir-menu-logo.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.woz-slots {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/woz-slots-menu-logo.jpeg') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.wwf {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/wwf-menu-logo.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.merge-dragons {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/merge-dragons.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.dragon-city {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/dragon-city.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.game-of-thrones-slots-casino {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/game-of-thrones-slots-casino-logo.png') no-repeat;
    background-size: cover;
  }
}
.menu-app-wrapper.willy-wonka-slots {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/willy-wonka-slots-logo.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.csr-racing-2 {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/csr-racing-2-logo.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.woz-magic-match {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/woz-magic-match-logo.png') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.monster-legends {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/monster-legends-logo.jpg') no-repeat;
    background-size: cover;
  }
}

.menu-app-wrapper.farmville-2 {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/farmville-2-logo.jpg') no-repeat;
    background-size: cover;
  }
}
.menu-app-wrapper.farmville-3 {
  .app-icon-wrapper {
    background: url('../../images/logo/menu/farmville-3-logo.jpg') no-repeat;
    background-size: cover;
  }
}
