.player-details-wrapper {
  @apply flex justify-center max-w-sm mx-auto;
}

.player-id {
  @apply hidden;
}

.player-name {
  font-size: 22px;
  line-height: 25px;

  .name {
    @apply flex;
  }
}

.player-details-wrapper .avatar {
  width: 130px;
}
.player-name-wrapper {
  @apply flex flex-col items-center;
}

.comma {
  @apply hidden;
}

.promotional-text {
  @apply hidden;
}
