.saved-cards {
  @apply relative mb-3;

  .saved-cards-trigger {
    @apply w-full bg-white text-black py-2 px-4 text-left font-helvetica font-normal flex items-center rounded;
    height: 44px;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgba(204, 204, 204, var(--tw-border-opacity));
  }

  .card-menu-bkg {
    @apply fixed top-0 left-0 right-0 bottom-0 bg-opacity-0;
    z-index: 100;
  }

  .card-action {
    color: #0091ff !important;
  }

  .brand {
    height: 26px;
    width: 48px;
    @apply border border-gray-300 rounded text-center mr-3 shadow;
    display: inline-block;
    background: url(../../images/card-brands.png) no-repeat;

    &.brand-visa {
      background-position: 0 -1px;
    }

    &.brand-mastercard {
      background-position: 0 -27px;
    }

    &.brand-amex {
      background-position: 0 -131px;
    }

    &.brand-discover {
      background-position: 0 -53px;
    }

    &.brand-jcb {
      background-position: 0 -79px;
    }

    &.brand-unionpay {
      background-position: 0 -105px;
    }

    &.brand-delete,
    &.brand-new {
      background-image: none;
      @apply border-0 mr-0 shadow-none;
      color: #0091ff;
    }
  }

  .saved-cards-menu {
    z-index: 101;

    @apply top-0 left-0 right-0 border border-gray-400 rounded;

    &.saved-cards-show {
      @apply block absolute;
    }

    &.saved-cards-hide {
      @apply hidden;
    }

    li {
      @apply text-black font-helvetica font-normal w-full py-2 px-4 bg-white text-left border-b border-gray-400;
      height: 44px;

      &:first-of-type {
        @apply rounded-t;
      }

      &:last-of-type {
        @apply rounded-b border-b-0;
      }

      button {
        @apply h-full w-full text-left flex items-center;
      }
    }
  }
}

.has-consent .saved-cards .saved-cards-trigger {
  @apply rounded-t rounded-b-none;
}

.has-consent.new-card .saved-cards .saved-cards-trigger {
  @apply rounded-t rounded-b;
}