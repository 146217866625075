.game-e-and-p {
  .home {
    background: url('../../images/landing/mobile/e-and-p/ep-hero.png') no-repeat;
    background-position: top center;
    background-position-y: 25px;
    background-size: auto 300px;

    .home-wrapper {
      background: url('../../images/logo/ep-logo.png') no-repeat;
      background-size: auto 92px;
      background-position: 45px 162px;
    }

    .message-container {
      @apply bg-white bg-gradient-to-b from-gray-200 to-gray-400 shadow-sm border-0;
      padding: 3px;

      .message-inner {
        @apply border-0 shadow-inner font-outline;
        background: linear-gradient(180deg, #1c4b6a 0%, #398aaf 100%);
      }

      .message {
        @apply font-normal text-center;
        margin: auto;
        max-width: 80%;
        font-size: 22px;
        line-height: 22px;
      }
    }
  }

  //Desktop

  .desktop-home-wrapper {
    background: url('../../images/landing/desktop/e-and-p/banner-E&P.png') no-repeat center center fixed;
    // background-size: 100% 100%;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .desktop-home-content {
      // height: calc(100vh - 3rem); //Header
      .game-logo {
        background: url('../../images/landing/desktop/e-and-p/e-and-p-desktop-logo.png') no-repeat;
        height: 140px;
        background-size: auto 140px;
      }
    }
  }
}
