.game-hir {
  .landing {
    background: url('../../images/landing/mobile/hit-it-rich/hir-landing-page-bg.png') no-repeat;
    background-size: 100% auto;
    background-position-x: center;

    .landing-wrapper {
      @apply pt-28;

      .free-coin {
        @apply text-rubber-ducky-yellow font-bold items-center flex w-full justify-center;
        font-size: 20px;
        line-height: 25px;
      }
    }

    .item-inner {
      @apply px-0 py-0;
    }

    .front-card {
      @apply justify-between;
    }
    .item-description {
      @apply flex text-xl;
    }

    .item-description-wrapper {
      @apply flex-row;
    }

    .product-content-items-wrapper {
      @apply relative block items-center;
    }

    .title-description {
      @apply w-full flex flex-col items-start justify-start text-left;
    }

    .item-description-wrapper {
      @apply flex w-full items-center mt-4;
    }

    .content-item-animate {
      @apply hidden;
    }
  }

  .landing-title {
    @apply hidden;
  }

  .landing-message-wrapper {
    @apply max-w-sm mx-auto;
  }
  .landing-message {
    @apply w-2/4 font-bold pl-2 purple-font-outline;
    font-size: 17px;
    line-height: 20px;
  }

  .landing-button-wrapper {
    @apply mt-2;
    .btn-inner {
      @apply font-bold;
      font-size: 22px;
      line-height: 25px;
    }
    .landing-button {
      white-space: pre-line;
    }
  }
}
