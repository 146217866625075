.game-poker {
  .cart {
    .marketing-msg {
      background: linear-gradient(180deg, #ac5300 0%, #f88c28 100%);
    }

    .bestvalue {
      background: #fc1840 !important;
    }

    .mostpopular {
      background: #fc1840 !important;
    }

    .dailyfreebie {
      background: #83d745 !important;
    }
  }
  .cart-title {
    font-size: 28px;
    line-height: 34px;
  }

  .limited-sales {
    .cart-item {
      @apply flex-col pt-0;
    }
  }

  .cart-item {
    @apply border-2 border-black rounded-md flex px-6 py-3 flex-col;
    background: linear-gradient(180deg, #4c0512 0%, #2f030b 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -moz-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    font-family: 'Helvetica Neue';

    .bonus-sale {
      @apply font-bebasNeue inline-flex px-6 pb-3 pt-1 self-center;
      p {
        font-size: 16px;
        line-height: 19px;
        filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
      }
      clip-path: polygon(0% 0%, 100% 0, 100% 70%, 50% 90%, 0 70%);
      width: fit-content;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
    }
  }

  .cart-item-inner {
    @apply w-full;
    min-height: 0px;
  }

  .cart-image-wrapper,
  .cart-description {
    @apply hidden;
  }

  .cart-details {
    @apply flex items-center justify-between w-full m-0;
  }

  .cart-name {
    @apply font-bold;
    font-size: 16px;
    line-height: 18px;
  }

  .cart-price {
    @apply font-bold;
    font-size: 16px;
    line-height: 19px;
  }

  .credit-card-form {
    @apply max-w-sm mx-auto;
  }

  .cart-item-contents-wrapper {
    @apply block mt-2;
  }

  .content-item {
    @apply flex items-center;
  }

  .content-item:not(:first-child) {
    margin-top: 2px;
  }

  .content-item-name {
    @apply ml-2;
    font-size: 14px;
    line-height: 23px;
    color: #f2a44a;
  }

  .credit-card-form {
    @apply max-w-sm;
  }

  .btn.cc {
    height: 50px;
    @apply rounded-md;

    .btn-inner {
      @apply pt-0;
    }

    .btn-extra {
      @apply hidden;
    }

    .credit-card {
      @apply font-signika font-bold;
      text-transform: none;
      font-size: 20px;
    }
  }

  .paypal-container {
    @apply max-w-sm;
  }

  .paypal-container .pp-button {
    @apply max-w-sm;
    border-radius: 6px;
  }

  .pp-button-text {
    font-size: 24px;
    line-height: 28px;
    filter: drop-shadow(1px 1px rgba(0, 0, 0, 0.25));
  }

  // .cc.primary {
  //   @apply font-normal text-white;
  //   background: linear-gradient(to right, #42e06b 0%, #21b387 100%);
  //   border-radius: 6px;
  // }
  .customer-support {
    @apply text-white;
  }

  .customer-support a {
    @apply text-poker-green;
  }
}