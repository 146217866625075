.btn {
  @apply text-white text-lg;
}

.btn {
  @apply relative p-1 w-full max-w-xs mx-auto block overflow-hidden;

  &:active {
    .btn-extra {
      top: 40%;
      bottom: 5px;
    }
  }
}

.btn:hover {
  // @apply opacity-90;
}

.btn:disabled {
  @apply opacity-50;
}

.btn-inner {
  @apply w-full px-6 rounded py-1 font-normal relative;
  // z-index: 1;
}

.btn-sm {
  @apply w-auto p-1 inline-block;
}

.btn-sm .btn-inner {
  @apply py-0 px-4 block;
  // z-index: 1;
}

.btn-sm {
  .btn-extra {
    bottom: 25%;
  }

  &:active {
    .btn-extra {
      top: 25%;
    }
  }
}

.btn-lg {
  @apply mx-auto py-2.5 font-normal text-center text-lg;
  // @apply mx-auto p-1 px-4 py-3 text-center text-hxl;
}

.btn-lg .btn-inner {
  @apply block px-3 font-normal;
  // z-index: 1;
}

.btn-zynga {
  @apply bg-zynga-red-dark border-zynga-red-dark;
}

button.btn-cancel {
  @apply w-full my-4;
  background: linear-gradient(180deg, #d1d1d1 14.7%, #ececeb 14.71%, #d6d6d6 14.72%, #a3a3a3 62.32%, #9c9c9c 74.32%);
  height: 50px;
}

button.btn-shop-now {
  @apply w-full mt-4 mb-8 bg-zynga-red rounded;
  height: 50px;
  font-family: 'Helvetica Neue';
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1%;
  max-width: 13rem !important;
}

@media (min-width: 769px) {
  button.btn-shop-now {
    @apply mb-20;
  }
}

@media (min-width: 1024px) {
  button.btn-shop-now {
    @apply mb-12;
  }
}

button.btn-zynga {
  @apply w-full;
  background: linear-gradient(180deg, #a30505 14.7%, #af0000 14.71%, #cf0202 14.72%, #ac0505 62.32%, #9c0000 74.32%);
}

button.btn-zynga .btn-extra {
  background: #e02323;
}

button.btn-submit {
  min-height: 50px;
}