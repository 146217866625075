.game-hir {
  .catalog {
    @apply pt-3;

    .product-content-items-wrapper {
      @apply flex mt-2 w-full justify-between;

      .btn-sm {
        min-width: fit-content;

        .btn-inner {
          min-width: 4.5rem;
        }
      }
    }
  }

  .item-list {
    @apply mt-6;
  }

  .item-outer {
    @apply mb-3 shadow-sm border-0 max-w-sm;

    background: transparent;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
  }

  .item-inner {
    @apply items-start justify-between relative rounded-lg;
    width: 100%;
    height: 100%;

    transition: transform 1s;
    -webkit-transition: -webkit-transform 1s;
    -o-transition: -o-transform 1s;
    -moz-transition: -moz-transform 1s;

    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;

    transform-origin: center right;
    -webkit-transform-origin: center right;
    -o-transform-origin: center right;
    -moz-transform-origin: center right;
  }

  .item-inner.is-flipped {
    transform: rotateX(180deg);
    -moz-transform: rotateX(180deg);
    -webkit-transform: rotateX(180deg);
    -o-transform: rotateX(180deg);

    .marketing-msg-outer {
      @apply opacity-0;

      p {
        @apply opacity-0;
      }
    }
  }

  .marketing-msg-outer {
    @apply opacity-100;
    transition: opacity linear .5s;

    p {
      @apply opacity-100;
      transition: opacity linear .5s;
    }
  }

  .front-card-outer {
    @apply flex w-full;
    padding: 1px;
    @apply shadow-inner border-0 rounded-lg h-full;
    max-width: 24rem;
    padding: 1px;
    background: linear-gradient(to right, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);

    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    transform: rotateX(0deg);
  }

  .front-card {
    @apply py-1.5 px-2 flex w-full rounded-lg flex-col;
    background: linear-gradient(180deg, #96069b 0%, #300049 100%);
  }

  .back-card-outer {
    @apply flex w-full absolute left-1/2 top-1/2 justify-between rounded-lg h-full shadow-inner;
    padding: 1px;
    width: 100%;

    transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -moz-transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -webkit-transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -o-transform: translateY(-50%) translateX(-50%) rotateX(180deg);

    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;

    background: linear-gradient(to right, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);
  }

  .back-card {
    @apply w-full rounded-lg flex pb-1.5 px-2;
    background: linear-gradient(180deg, #300049 0%, #96069b 100%);
  }

  .back-card-items-wrapper {
    @apply flex flex-col;
    max-width: 100%;
  }

  .back-card-content-wrapper {
    @apply flex;
  }

  .back-card-item-name {
    @apply text-rubber-ducky-yellow ml-4 purple-font-outline;
    font-size: 20px;
    line-height: 25px;
  }

  .non-primary-content-wrapper {
    @apply flex;
    max-width: 100%;
    overflow: auto hidden;
  }

  .non-primary-content-items {
    display: flex;
  }

  .item-image {
    @apply inline;
    max-width: 5rem;
  }

  .item-description-wrapper {
    @apply flex flex-col w-full;
  }

  .product-content-item-image-wrapper {
    width: 2.5rem;
  }

  .product-content-item-image-wrapper-back {
    width: 2.5rem;
  }

  .product-content-items-text-wrapper {
    @apply flex flex-col items-start mx-1;
  }

  .content-item-animate {
    @apply relative overflow-hidden w-full;
    height: 35px;
  }

  .product-content-item {
    @apply flex items-center justify-start rounded pr-0.5 pl-1 text-left relative overflow-hidden;
    background: #35063b;
    font-size: 11px;
    line-height: 13px;

    box-shadow: -1px 0px 1px rgba(249, 26, 231, 0.5) inset, 0px -1px 1px rgba(249, 26, 231, 0.5) inset,
      1px 0px 1px rgba(249, 26, 231, 0.5) inset, 0px 3px 4px rgba(0, 0, 0, 0.48) inset;
  }

  .product-content-item,
  .front {
    width: 130px;
    height: 35px;
  }

  .product-content-item,
  .back {
    width: 98px;
    height: 35px;
  }

  @media (min-width: 410px) {

    .product-content-item,
    .back {
      width: 110px;
    }

    .product-content-item {
      @apply pl-2;
    }
  }

  .content-item-animate>* {
    position: absolute;
  }

  .animate-item {
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }

  .show {
    opacity: 1;
  }

  .one-item .product-content-item {
    top: 0;
  }

  .item-name {
    @apply flex text-rubber-ducky-yellow purple-font-outline;
    font-size: 24px;
    line-height: 30px;
  }

  .product-content-item-name {
    @apply text-left;
  }

  .item-help-mark {
    @apply flex flex-col justify-between items-end absolute px-3 py-2 top-0 right-0 cursor-pointer;
  }

  .item-image-wrapper {
    @apply flex items-center justify-center top-4 bottom-2 left-2 w-1/3;
    max-width: 5rem;
  }

  .item-details {
    @apply flex text-center ml-4 mb-2 w-full items-center justify-between;
  }

  .item-description {
    @apply hidden;
  }

  .info-btn-container {
    @apply w-6 h-6 flex justify-end;
  }

  .free-coin-outer {
    @apply flex items-center w-full shadow-inner border-0 rounded-lg font-bold mx-auto;
    font-size: 18px;
    line-height: 21px;
    padding: 1px;
    max-width: 24rem;
    padding: 1px;
    background: linear-gradient(to right, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);
  }

  .free-coin-inner {
    @apply items-center border-0 py-1 px-2 flex w-full rounded-lg;
    background: linear-gradient(#2e68c1 0%, #001d55 50%, #001d55 100%);
  }

  .sales-banner-container {
    @apply border-0 mt-4 max-w-sm mx-auto;
    background: linear-gradient(to right, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);
    padding: 1px;
    border-radius: 0.6rem;
  }

  .title-description {
    @apply flex flex-col items-start justify-center;
  }

  .item-pre-sale-amount {
    @apply font-normal break-words light-purple-font-outline;
    font-size: 16px;
    line-height: 20px;
    color: rgba(255, 255, 255);
  }

  .product-name {
    @apply flex font-lato text-black;
    font-size: 15px;
    line-height: 18px;
  }

  .items-container {
    @apply flex w-full;
    border-radius: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-bottom: 0.375rem;
  }

  .mostpopular-wrapper {
    background: #fe9ae2 !important;
  }

  .bestvalue-wrapper {
    background: #abfd4f !important;
  }

  .marketing-msg-outer {
    @apply absolute flex items-center justify-center capitalize;
    z-index: 11;
    top: 50%;
    transform: translate(0, -50%);
    left: -16px;
    filter: drop-shadow(0 0px 3px rgba(0, 0, 0, 1.0));
  }

  .marketing-msg-wrapper {
    background-color: #fac592;
    @apply py-px pr-px flex items-center justify-center capitalize;
    clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

    .bestvalue {
      background: linear-gradient(to bottom, #57df67, #58e068, #009411, #009311) !important;
    }

    .mostpopular {
      background: linear-gradient(to bottom, #e8408f, #e63d8b, #d6226c, #d21b64) !important;
    }

    .marketing-msg {
      background: linear-gradient(to bottom, #f9a150, #f4953c, #f78a24, #f68012);
      @apply inline-flex relative text-center w-min;
      padding: 0.3rem 1rem 0.5rem 0.25rem;
      white-space: break-spaces;
      clip-path: polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%);

      p {
        @apply font-signika font-bold;
        font-size: 12px;
        line-height: 11px;

        transform: rotate(-9.57deg);
        -webkit-transform: rotate(-9.57deg);
        -moz-transform: rotate(-9.57deg);
        -ms-transform: rotate(-9.57deg);
        -o-transform: rotate(-9.57deg);
      }
    }
  }

  // Default pos - top left
  .bonus-sale-outer {
    @apply justify-start;
    margin-bottom: -24px;
    top: -12px;
  }

  .bonus-sale-outer.top {
    top: -12px;
  }

  .bonus-sale-outer.left {
    @apply justify-start;
    margin-bottom: -24px;
  }

  .bonus-sale-outer.center {
    @apply justify-center;
    margin-bottom: -12px;
  }

  .bonus-sale-outer {
    @apply flex items-center capitalize;
    position: relative;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));

    .bonus-sale-inner {
      @apply bg-black;
      padding: 2px;
      clip-path: polygon(0% 0%, 100% 0, 100% 70%, 50% 90%, 0 70%);
      z-index: 10;

      .bonus-sale {
        @apply font-signika inline-flex px-4 pb-3 pt-1 self-center carmine-font-outline;
        background: linear-gradient(#ff123c, #610011);

        p {
          font-size: 14px;
          line-height: 18px;
        }

        clip-path: polygon(0% 0%, 100% 0, 100% 70%, 50% 90%, 0 70%);
        width: fit-content;
      }

      .left {
        @apply carmine-font-outline;
        background: linear-gradient(#ff123c, #610011) !important;
      }

      .center {
        background: linear-gradient(#1254ff, #002c61) !important;
        @apply egyptian-blue-font-outline;
      }
    }
  }
}