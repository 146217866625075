.landing {
  @apply pt-5 h-full;
}
.landing-wrapper {
  @apply pt-12 h-full px-4;
}
.landing-message {
  @apply text-center mb-6;
}
.landing-button-wrapper {
  @apply mt-6;
}

.landing .item-help {
  @apply hidden;
}

.mobile-landing-wrapper {
  @apply w-full h-full flex flex-col justify-between;
}

.game-neutral-landing {
  @apply mx-auto flex flex-row flex-wrap;
  max-width: 720px;

  .e-and-p-landing-image-wrapper {
    background: url('../../images/landing/mobile/e-and-p/e-&-p-game-neutral.png') no-repeat;
  }
  .poker-landing-image-wrapper {
    background: url('../../images/landing/mobile/poker/poker-game-neutral-bg.png') no-repeat;
  }
  .hir-landing-image-wrapper {
    background: url('../../images/landing/mobile/hit-it-rich/hir-game-neutral.png') no-repeat;
  }
  .woz-slots-landing-image-wrapper {
    background: url('../../images/landing/mobile/woz-slots/woz-slots-game-neutral.png') no-repeat;
  }

  .wwf-landing-image-wrapper {
    background: url('../../images/landing/mobile/wwf/wwf-game-neutral.png') no-repeat;
  }

  .merge-dragons-landing-image-wrapper {
    background: url('../../images/landing/mobile/merge-dragons/merge-dragons-game-neutral.png') no-repeat;
  }

  .dragon-city-landing-image-wrapper {
    background: url('../../images/landing/mobile/dragon-city/dragon-city-game-neutral.png') no-repeat;
  }

  .game-of-thrones-slots-casino-landing-image-wrapper {
    background: url('../../images/landing/mobile/game-of-thrones-slots-casino/game-of-thrones-slots-casino-game-neutral.png')
      no-repeat;
  }
  .willy-wonka-slots-landing-image-wrapper {
    background: url('../../images/landing/mobile/willy-wonka-slots/willy-wonka-slots-game-neutral.png') no-repeat;
  }

  .csr-racing-2-landing-image-wrapper {
    background: url('../../images/landing/mobile/csr-racing-2/csr-racing-2-game-neutral.png') no-repeat;
  }

  .woz-magic-match-landing-image-wrapper {
    background: url('../../images/landing/mobile/woz-magic-match/woz-magic-match-game-neutral.jpeg') no-repeat;
  }

  .monster-legends-landing-image-wrapper {
    background: url('../../images/landing/mobile/monster-legends/monster-legends-game-neutral.jpg') no-repeat;
  }

  .farmville-2-landing-image-wrapper {
    background: url('../../images/landing/mobile/farmville-2/farmville-2-game-neutral.jpg') no-repeat;
  }

  .farmville-3-landing-image-wrapper {
    background: url('../../images/landing/mobile/farmville-3/farmville-3-game-neutral.jpg') no-repeat;
  }

  .coming-soon-apps-landing-image-wrapper {
    background: url('../../images/landing/mobile/coming-soon-apps.png') no-repeat;
  }

  .app-wrapper {
    @apply flex flex-col items-center font-bold justify-end font-helvetica font-outline pb-12;
    font-size: 18px;
    line-height: 27px;
    min-height: 18rem;
    width: 50%;

    background-position: center 10%;
    background-size: cover;

    p {
      @apply text-center;
      margin-bottom: -1rem;
    }
  }
  @media (min-width: 769px) {
    .app-wrapper {
      @apply pb-24;
      font-size: 30px;
      line-height: 36px;
    }
  }
}
.generic-page-footer {
  flex: 1;
  @apply flex items-center justify-center text-cadet-blue font-normal font-helvetica text-center py-3;
  font-size: 12px;
  line-height: 18px;
}

.free-coin {
  @apply hidden;
}
