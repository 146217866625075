@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Lalezar-Regular';
  src: url(./fonts/Lalezar-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Lato-Regular';
  src: url(./fonts/Lato-Regular.woff) format('woff');
}

@font-face {
  font-family: 'BebasNeue-Regular';
  src: url(./fonts/BebasNeue-Regular.woff) format('woff');
}

@font-face {
  font-family: 'Signika-Regular';
  src: url(./fonts/Signika-Regular.woff) format('woff');
}

@font-face {
  font-family: 'OpenSans-Regular';
  src: url(./fonts/OpenSans-Regular.woff) format('woff');
}

@font-face {
  font-family: 'MyriadPro-Regular';
  src: url(./fonts/MyriadPro-Regular.woff) format('woff');
}

html {
  @apply h-full;
}

body {
  @apply bg-black text-white h-full m-0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background: radial-gradient(50% 50% at 50% 50%, #2e527e 0%, #17273c 100%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
input[type='text'],
input[type='email'] {
  @apply text-black rounded w-full h-full max-h-11 px-3 border-gray-400 border pl-10;
}
input[type='text']:disabled,
input[type='email']:disabled {
  @apply bg-white;
}
input:focus-visible,
input:focus {
  @apply outline-none;
}
button:hover {
  // @apply bg-opacity-90;
}
button:disabled {
  @apply opacity-40 cursor-not-allowed;
}
select,
option {
  @apply text-black;
}
select {
  @apply rounded px-3 h-input;
}
#root {
  @apply h-full;
}

//game background
.game-e-and-p {
  #root {
    @apply font-lalazar;
    background: radial-gradient(50% 50% at 50% 50%, #2e527e 0%, #17273c 100%);
  }
}

.game-poker {
  #root {
    @apply font-bebasNeue;
    background: linear-gradient(transparent, #2a0101 100%), url('./images/poker/poker-bg.png') repeat;
  }

  .home-page-background {
    background: url('./images/poker/poker-bg.png') repeat !important;
  }
}

.game-hir {
  #root {
    @apply font-signika font-bold;
    background: linear-gradient(#79007d 20%, #300049 100%);
  }
}
