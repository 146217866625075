.receipt {
  @apply text-center px-4;
  padding-top: 10px;
}
.purchase-complete {
  @apply font-normal;
  font-size: 28px;
  line-height: 44px;
}
.gems-purchased {
  @apply font-normal;
  font-size: 22px;
  line-height: 14px;
}

.receipt h2 {
  @apply text-center font-bold;
  font-size: 12px;
}
.receipt-image-wrapper {
  @apply m-3 text-center h-48 flex justify-center items-center relative;
  .glow-bg {
    @apply absolute inset-0;
    right: 10px;
    background-image: url('../../images/blue-glow.png');
    background-repeat: no-repeat;
    background-position: center;
    mix-blend-mode: screen;
    z-index: -1;
  }
}
.receipt-image {
  @apply h-48 inline;
}
.receipt-outer {
  @apply rounded bg-gray-100 text-black p-2 mt-6 mb-2 text-left border-2 border-card-border max-w-sm mx-auto;
}
.receipt-inner {
  @apply grid grid-cols-2 gap-y-0.5;
}
.receipt-label {
  @apply font-bold;
  font-family: 'Helvetica Neue';
  font-size: 12px;
  line-height: 22px;
  color: rgba(0, 0, 0, 0.8);
  word-break: break-all;
}
.receipt-value {
  @apply font-normal;
}
.receipt-support {
  @apply text-center mb-8 text-sm px-6;
}
.receipt-support a {
  @apply text-link-green;
}

.purchase-details-wrapper {
  @apply hidden max-w-sm mx-auto;
}
