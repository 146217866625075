.game-poker {
  .item-button-wrapper {
    /* @apply pb-2; */
  }

  .bestvalue {
    background: #fc1840 !important;
  }

  .mostpopular {
    background: #fc1840 !important;
  }

  .dailyfreebie {
    background: #83d745 !important;
  }

  .catalog {
    padding-top: 1rem;

    .item-outer {
      @apply my-3;
      background: transparent;

      backface-visibility: hidden;
      -moz-backface-visibility: hidden;
      -webkit-backface-visibility: hidden;
      -o-backface-visibility: hidden;
    }

    .item-list {
      @apply mt-0;
    }

    .item-inner {
      @apply items-start justify-between relative;
      width: 100%;
      height: 100%;

      transition: transform 1s;
      -webkit-transition: -webkit-transform 1s;
      -o-transition: -o-transform 1s;
      -moz-transition: -moz-transform 1s;

      transform-style: preserve-3d;
      -webkit-transform-style: preserve-3d;
      -o-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;

      transform-origin: center right;
      -webkit-transform-origin: center right;
      -o-transform-origin: center right;
      -moz-transform-origin: center right;
    }

    .product-name {
      @apply flex font-lato text-black;
      font-size: 15px;
      line-height: 18px;
    }

    .item-details {
      @apply mt-1.5;
    }

    .item-name {
      @apply mt-1;
    }

    .item-description {
      @apply mt-1;
    }

    .item-image {
      @apply inline;
      max-width: 4.5rem;
    }

    .item-button-wrapper {
      @apply mt-1;
    }

    .item-inner.is-flipped {
      transform: rotateX(180deg);
      -moz-transform: rotateX(180deg);
      -webkit-transform: rotateX(180deg);
      -o-transform: rotateX(180deg);
    }
  }

  .limited-offer-wrapper {
    @apply flex justify-between mx-2 font-myriadPro mt-4;

    .limited-quantity-wrapper {
      @apply inline-flex rounded-t-lg px-6 py-0.5 border-t-2 border-l-2 border-r-2 font-bold;
      background: #323232;
      border-color: #63646a;
      font-size: 16px;
      line-height: 19px;
    }
  }

  .bonus-sale-wrapper {
    @apply inline-flex absolute;
    padding: 0.15rem 1rem 0.1rem 0.25rem;

    p {
      @apply font-bebasNeue font-normal;
      font-size: 16px;
      line-height: 19px;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
    }

    clip-path: polygon(0% 0%, 85% 0%, 100% 50%, 85% 100%, 0% 100%);
    z-index: 11;
    top: 5px;
    left: -15px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
  }

  .marketing-msg {
    background: #f88c28;
  }

  .item-outer {
    @apply my-6 py-2 pr-2 bg-white bg-gradient-to-b from-gray-200 to-gray-400 shadow-sm border-0;
    padding: 0.5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 24rem;
  }

  .item-inner {
    @apply shadow-inner border rounded-lg border-white h-full flex items-center;
    border-width: 0.5px;
  }

  .item-name {
    @apply font-normal text-lg break-words;
    font-size: 28px;
    line-height: 32px;
    filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.5));
  }

  .item-pre-sale-amount {
    @apply font-normal break-words uppercase;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.8);
  }

  .item-image-wrapper {
    @apply flex-col justify-end flex items-center top-4 bottom-2 left-2 w-1/3;
    // width: 45%;
  }

  .item-details {
    @apply flex items-start justify-between mr-2 flex-col text-center ml-4;
    width: 48%;
  }

  .item-description-wrapper {
    @apply flex flex-col items-start text-left mr-4;
  }

  .item-description {
    @apply hidden;
  }

  .catalog-tabs-wrapper {
    @apply flex justify-between items-center max-w-sm mx-auto;
  }

  .catalog-tabs-item-outer:not(:last-child) {
    margin-right: 10px;
  }

  .catalog-tabs-item-outer {
    @apply mb-0;
    flex: 1;
    @apply mt-6 shadow-sm border-0 rounded-lg border-white mx-auto;
    padding: 0.5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    max-width: 12rem;
  }

  .catalog-tabs-item-outer.selected {
    @apply bg-white bg-gradient-to-b from-gray-200 to-gray-400 border-white;
  }

  .catalog-tabs-item-inner {
    @apply py-1 flex items-center justify-center border-0 shadow-inner rounded-lg h-full;
    height: 55px;
    background: linear-gradient(180deg, rgba(242, 164, 74, 0.5) 0%, rgba(205, 110, 68, 0.5) 100%);
  }

  .catalog-tabs-item-inner.selected {
    background: linear-gradient(180deg, #f2a44a 0%, #cd6e44 100%);
  }

  .catalog-tabs-item-name {
    font-size: 28px;
    line-height: 24px;
  }

  .product-content-item {
    @apply flex items-center justify-center px-1 rounded-sm;
    background: #a45739;
  }

  .question-mark-container {
    @apply w-12 h-12 flex justify-end;
  }

  .question-mark-img {
    @apply w-7 h-7;
  }

  .close-mark-img {
    @apply w-6 h-6;
  }

  .product-content-items-wrapper {
    @apply flex mt-2;
  }

  .product-content-item-name {
    @apply font-normal mr-2;
    font-size: 14px;
    line-height: 18px;
  }

  .product-content-item-image-wrapper {
    width: 12px;
  }

  .front-card,
  .back-card {
    @apply py-1.5 pr-1.5 pl-6;
  }

  .front-card {
    @apply pr-6 pl-4 rounded-md;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    background: linear-gradient(180deg, #f2a44a 0%, #cd6e44 100%);
    transform: rotateX(0deg);
  }

  .limited-sales {
    .item-inner {
      border-width: 2px;

      .front-card {
        background: radial-gradient(100% 100% at 50% 50%, #ae1724 0%, #d84936 53.65%, #ec4d81 73.96%) !important;
      }
    }
  }

  .back-card {
    @apply absolute left-1/2 top-1/2 flex py-1.5 justify-between pr-6 pl-4 rounded-md h-full;
    width: 100%;

    transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -moz-transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -webkit-transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -o-transform: translateY(-50%) translateX(-50%) rotateX(180deg);

    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;

    background: linear-gradient(180deg, #99522c 0%, #893c28 100%);
  }

  .item-help-mark {
    @apply flex flex-col h-full justify-between items-end absolute px-3 py-2 top-0 right-0;
  }

  .back-card-items-wrapper {
    @apply flex flex-col justify-center;
  }

  .back-card-item:not(:first-child) {
    margin-top: 2px;
  }

  .back-card-item {
    @apply flex items-center px-2 rounded-sm;
    min-width: 14rem;
  }

  .back-card-item-name {
    @apply font-normal ml-2 text-white;
    font-family: 'Helvetica Neue';
    font-size: 14px;
    line-height: 26px;
  }

  .free-coin-front,
  .free-coin-back {
    @apply py-1.5 pr-1.5 pl-6;
  }

  .free-coin-front {
    @apply pr-6 pl-4 rounded-lg font-lato;
    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    transform: rotateX(0deg);

    font-size: 15px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.8);
    max-width: 24rem;
    background: linear-gradient(to bottom, #380229, #7a0239);

    .item-details {
      width: 60%;
    }
  }

  .free-coin-back {
    @apply absolute left-1/2 top-1/2 flex py-1.5 justify-between pr-6 pl-4 rounded-lg h-full w-full font-lato;
    font-size: 13px;
    line-height: 16px;
    color: rgba(255, 255, 255, 0.8);

    transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -moz-transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -webkit-transform: translateY(-50%) translateX(-50%) rotateX(180deg);
    -o-transform: translateY(-50%) translateX(-50%) rotateX(180deg);

    backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -o-backface-visibility: hidden;

    background: linear-gradient(180deg, #7a0239 0%, #380229 100%);
  }

  .free-coin-back-card-items-wrapper {
    @apply flex justify-center items-center;
    width: 90%;
  }
}