.cart {
  @apply h-full min-h-full block px-4;
  padding-top: 50px;
}

.cart h2 {
  @apply text-lg text-center font-bold pt-8;
}

.cart .fa-icon {
  @apply mr-3;
}

.cart-title {
  text-align: center;
  font-size: 22px;
  line-height: 34px;
  @apply font-normal font-outline;
}

.cart-error {
  @apply max-w-sm mx-auto mb-4;
}

.cart-item {
  @apply relative my-3 mb-8 py-2 pr-2 border rounded-lg border-white bg-white mx-auto;
  max-width: 24rem;
}

.cart-item-inner {
  min-height: 84px;
}

.cart-image-wrapper {
  @apply flex items-center justify-center top-4 bottom-2 left-2 w-24;
}

.cart-image {
  @apply inline;
}

.cart-details {
  @apply font-outline ml-4;
}

.cart-name {
  @apply font-normal text-lg;
}

.cart-description {
  @apply text-sm;
}

.cart-price {
  @apply font-normal text-lg;
}

.cart-tax {
  top: -20px;
  @apply text-sm relative;
}

.credit-cart-text {
  @apply font-normal font-outline text-lg;
}

.cart-item-contents-wrapper {
  @apply hidden;
}

.card-error,
.cart-error {
  @apply font-normal;
  font-family: 'Helvetica Neue';
  font-size: 12px;
  line-height: 14px;
  color: #f2a44a;
}

.separator {
  @apply mt-6 mb-4;
  opacity: 0.4;
  border: 0.5px solid #ffffff;
}

.purchase-agreement {
  @apply font-helvetica text-left font-normal;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 11px;
  line-height: 13px;
  color: rgba(255, 255, 255, 0.7);

  u {
    text-decoration-color: rgba(255, 255, 255, 0.3);
  }
}

.eu-consent {
  @apply relative flex px-2 py-3 text-left rounded-b font-helvetica border-l border-r border-b font-normal;
  font-size: 11px;
  line-height: 13px;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.5);
  border-color: #cccccc;
}

.customer-support {
  @apply text-center font-normal pb-4 sticky;
  font-size: 12px;
  line-height: 16px;
  color: #8ac8e5;
  position: sticky;
  font-family: 'Helvetica Neue';
}

.customer-support a {
  @apply text-link-green;
}

.paypal-container {
  @apply max-w-xs mx-auto mb-4 relative;
  z-index: 1;
  min-height: 50px;
}

.card-expiry {
  @apply rounded-bl;
}

.card-cvc {
  @apply rounded-br;
}

.has-zip .card-expiry,
.has-zip .card-cvc,
.has-consent .card-expiry,
.has-consent .card-cvc {
  border-radius: 0;
}

.delete-cards {
  @apply my-3 text-left rounded-md bg-white text-black font-helvetica font-normal border-gray-300 border;

  li {
    @apply py-2 px-3 border-b border-gray-400;

    label {
      @apply w-full flex;
    }

    &:last-child {
      border-bottom-width: 0 !important;
    }
  }
}

.stripe-field {
  @apply relative bg-white border border-card-border h-11 pt-3 pr-4 pl-10;
}

.credit-card-field {
  @apply bg-white;
}

input.email-field,
input.postal-code {
  @apply pl-10;
  font-size: 16px;
  font-family: 'Helvetica Neue';
  font-weight: 700;
  line-height: 16px;
  background-color: #fff;
}

input.email-field::placeholder,
input.postal-code::placeholder {
  @apply font-bold;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  line-height: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.btn.cc {
  @apply drop-shadow-none border border-black;
  background-color: #0073b8;
  border-radius: 5px;
}

.cc-form .fa-icon {
  @apply absolute left-2;
  top: 50%;
  transform: translate(0, -50%);
  color: rgba(28, 48, 74, 0.8);
}

.cc-form .fa-icon.fa-disabled {
  @apply text-gray-300;
}

.email-wrapper {
  @apply relative grid grid-cols-1 h-11;
}

div.card-cvc {
  @apply rounded-bl-none;
}

input.postal-code {
  @apply border-t-0 rounded-t-none;
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

input.invalid-field {
  color: #f2a44a;
}

#pp-button {
  @apply max-w-sm border border-black;
}

.paypal-button-label-container {
  opacity: 0;
}

.pp-button .btn-extra {
  @apply bg-opacity-20 bg-white;
}

.pp-wrapper {
  height: 50px;
  border-radius: 5px;
  @apply overflow-hidden;
}

.fa-cc {
  @apply inline-block;
}

.credit-card {
  @apply inline-block pl-2;
}

.breaker {
  @apply flex max-w-sm mx-auto;
  font-family: 'Helvetica';
  font-size: 16px;
  line-height: 18px;
}

.breaker:before,
.breaker:after {
  @apply mx-4 my-auto;
  content: '';
  flex: 1 1;
  border-bottom: 1px solid #979797;
}

.remember-me {
  @apply text-left px-4 bg-white bg-opacity-20 font-normal rounded flex items-center font-helvetica;
  height: 44px;

  label {
    @apply font-normal w-full;

    input {
      @apply mr-4;
    }
  }
}

.apple-pay {
  height: 50px;
  @apply mb-4 relative;
}

.stripe-vendor-outer {
  @apply mx-auto max-w-sm;
  border-radius: 5px;
  height: 50px;

  .StripeElement {
    iframe:first-of-type {
      height: 50px !important;
    }
  }
}

.loading-apple-pay,
.button-loading {
  @apply mb-4 mx-auto max-w-sm h-12 opacity-60 absolute top-0 left-0 right-0 bottom-0;
  @apply bg-white;
  border-radius: 5px;
}