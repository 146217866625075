.home {
  @apply h-full relative;

  &.game-neutral {
    .home-wrapper {
      padding-top: 20vh;
    }

    .message-container {
      @apply p-0;

      .message-inner {
        @apply bg-gradient-to-t from-zynga-red-dark to-zynga-red-dark;
      }
    }
  }

  .home-wrapper {
    @apply px-4 h-full w-full absolute flex flex-col items-center;
    padding-top: 304px;
  }

  .home-outer-wrapper {
    @apply flex max-w-sm mx-auto h-full w-full justify-center relative;
  }

  .message-container {
    @apply relative py-2 pr-2 border rounded-lg border-white bg-white;
    margin: 0;
    /* top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */

    .message-inner {
      @apply text-center text-lg py-4 border rounded-lg border-white bg-gradient-to-t from-gray-400 to-blue-800 h-full flex flex-col justify-center items-center;
      min-height: 98px;
    }

    .message {
      @apply py-4;

      p + p {
        @apply pt-4;
      }
    }
  }

  .qr-code {
    @apply mt-3;
  }

  .g-play-button {
    width: 200px;
  }

  &.show-browser-hint {
    a,
    a img {
      @apply invisible pointer-events-none;
    }

    .footer {
      @apply invisible;
    }
  }

  .browser-hint-bkg {
    @apply fixed top-0 right-0 left-0 bottom-0 bg-black bg-opacity-50 pointer-events-none;
  }

  .browser-hint-wrapper {
    @apply absolute mx-auto w-full px-4;
    top: 15vh;
    max-width: 22rem;
  }

  .browser-hint {
    .message-inner {
      @apply text-center px-5;

      .browser-icon {
        @apply h-16 w-16 inline-block;
      }

      h3 {
        @apply text-2xl pt-4 pb-4;
      }

      p {
        @apply text-left leading-5 text-lg;
      }

      ol {
        @apply pb-6 pt-6 leading-5 text-lg px-4;
      }
    }
  }
}

.desktop-home-wrapper {
  @apply h-full flex justify-center;

  .desktop-home-content {
    @apply h-full;
    font-family: 'Helvetica Neue';

    .game-name {
      @apply font-bold;
      font-size: 48px;
      line-height: 44px;
    }

    .text-container {
      @apply relative;
      width: 40%;
    }

    .desktop-home-footer {
      @apply font-normal pb-9 pt-8 sticky;
      top: 100vh;
      color: rgba(255, 255, 255, 0.6);
      font-size: 14px;
      line-height: 24px;
    }

    .qr-screen-wrapper {
      @apply relative flex items-center justify-center;
    }

    .qr-arrow {
      @apply absolute;
      top: 50%;
      right: -160px;
      z-index: 1;
      transform: translate(0%, -50%);
    }

    .qr-screen {
      height: 600px;
    }

    .qr-code {
      @apply m-6;
    }

    .qr-wrapper {
      position: absolute;
      z-index: 1;
    }

    .qr-wrapper {
      background: none;
      border: none;
      color: #000;
      box-sizing: content-box;
      border: 4px solid transparent;
    }

    .qr-wrapper::before,
    .qr-wrapper::after,
    span::before,
    span::after {
      display: block;
      content: '';
      width: 60px;
      height: 60px;
      position: absolute;
    }

    .qr-wrapper::before {
      top: -4px;
      left: -4px;
      border-top: 4px solid #000;
      border-left: 4px solid #000;
      border-top-left-radius: 24px;
    }

    .qr-wrapper::after {
      top: -4px;
      right: -4px;
      border-top: 4px solid #000;
      border-right: 4px solid #000;
      border-top-right-radius: 24px;
    }

    .qr-frame::before {
      bottom: -4px;
      left: -4px;
      border-bottom: 4px solid #000;
      border-left: 4px solid #000;
      border-bottom-left-radius: 24px;
    }

    .qr-frame::after {
      bottom: -4px;
      right: -4px;
      border-bottom: 4px solid #000;
      border-right: 4px solid #000;
      border-bottom-right-radius: 24px;
    }
  }

  .game-neutral {
    @apply flex h-full w-full flex-col relative;

    .apps-wrapper {
      @apply flex h-full overflow-auto relative;
    }

    .desktop-home-content-wrapper {
      @apply flex h-full w-full relative;
    }

    .scrolling-container {
      @apply absolute rounded-full justify-center items-center flex p-0.5;
      z-index: 10 !important;
      top: 5%;
      right: 5%;
      background: #efeded;
      filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.64));
    }

    .scroll {
      @apply p-4;
    }

    .active-scroll {
      @apply rounded-full;

      &:hover {
        animation: fadeoutBg 0.7s;
        -moz-animation: fadeoutBg 0.7s; /* Firefox */
        -webkit-animation: fadeoutBg 0.7s; /* Safari and Chrome */
        -o-animation: fadeoutBg 0.7s; /* Opera */
        background: radial-gradient(76.92% 133.3% at 76.4% 84.62%, #ff891d 0%, #ffc700 100%);
        box-shadow: 0px 4px 6px rgba(255, 143, 27, 0.66), inset -1px 0px 0px #ffa113, inset 1px 0px 0px #ff9900,
          inset 0px -1px 0px #ff9900, inset 0px 1px 0px #ff9900;
      }
    }

    @keyframes fadeoutBg {
      from {
        background: transparent;
        box-shadow: none;
      }
      to {
        background: radial-gradient(76.92% 133.3% at 76.4% 84.62%, #ff891d 0%, #ffc700 100%);
        box-shadow: 0px 4px 6px rgba(255, 143, 27, 0.66), inset -1px 0px 0px #ffa113, inset 1px 0px 0px #ff9900,
          inset 0px -1px 0px #ff9900, inset 0px 1px 0px #ff9900;
      }
    }

    @-moz-keyframes fadeoutBg {
      /* Firefox */
      from {
        background: transparent;
        box-shadow: none;
      }
      to {
        background: radial-gradient(76.92% 133.3% at 76.4% 84.62%, #ff891d 0%, #ffc700 100%);
        box-shadow: 0px 4px 6px rgba(255, 143, 27, 0.66), inset -1px 0px 0px #ffa113, inset 1px 0px 0px #ff9900,
          inset 0px -1px 0px #ff9900, inset 0px 1px 0px #ff9900;
      }
    }

    @-webkit-keyframes fadeoutBg {
      /* Safari and Chrome */
      from {
        background: transparent;
        box-shadow: none;
      }
      to {
        background: radial-gradient(76.92% 133.3% at 76.4% 84.62%, #ff891d 0%, #ffc700 100%);
        box-shadow: 0px 4px 6px rgba(255, 143, 27, 0.66), inset -1px 0px 0px #ffa113, inset 1px 0px 0px #ff9900,
          inset 0px -1px 0px #ff9900, inset 0px 1px 0px #ff9900;
      }
    }

    @-o-keyframes fadeoutBg {
      /* Opera */
      from {
        background: transparent;
        box-shadow: none;
      }
      to {
        background: radial-gradient(76.92% 133.3% at 76.4% 84.62%, #ff891d 0%, #ffc700 100%);
        box-shadow: 0px 4px 6px rgba(255, 143, 27, 0.66), inset -1px 0px 0px #ffa113, inset 1px 0px 0px #ff9900,
          inset 0px -1px 0px #ff9900, inset 0px 1px 0px #ff9900;
      }
    }

    .desktop-home-content {
      @apply font-helvetica font-bold text-center relative overflow-y-scroll;
      font-size: 34px;
      line-height: 44px;
      flex: 1;
      transition: all 0.5s ease;
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      width: 32vw;

      &:hover {
        width: 38vw;
      }

      .game-qr-text {
        @apply font-light;
        font-size: 24px;
        line-height: 36px;
      }

      @media (min-width: 1200px) {
        & {
          width: 25vw;
        }
        &:hover {
          width: 30vw;
        }
      }
      @media (min-width: 1700px) {
        & {
          width: 20vw;
        }
        &:hover {
          width: 25vw;
        }
      }

      &:hover {
        .qr-text-container {
          top: 0%;
        }
      }

      .text-container {
        @apply relative;
        height: 53%;
        overflow: hidden;
      }

      .qr-text-container {
        @apply absolute w-full text-center;
        top: 100%;
        vertical-align: bottom;
        -webkit-transition: all 500ms ease-in-out;
        -moz-transition: all 500ms ease-in-out;
        -o-transition: all 500ms ease-in-out;
        -ms-transition: all 500ms ease-in-out;
        transition: all 500ms ease-in-out;
      }
    }

    .e-and-p {
      background: url('../../images/landing/desktop/e-and-p/e-and-p-desktop-game-neutral.jpg') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .poker {
      background: url('../../images/landing/desktop/poker/poker-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .hir {
      background: url('../../images/landing/desktop/hit-it-rich/hir-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .woz-slots {
      background: url('../../images/landing/desktop/woz-slots/woz-slots-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .wwf {
      background: url('../../images/landing/desktop/wwf/wwf-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .merge-dragons {
      background: url('../../images/landing/desktop/merge-dragons/merge-dragons-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .dragon-city {
      background: url('../../images/landing/desktop/dragon-city/dragon-city-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .game-of-thrones-slots-casino {
      background: url('../../images/landing/desktop/game-of-thrones-slots-casino/game-of-thrones-slots-casino-desktop-game-neutral.png')
        no-repeat;
      background-position: top;
      background-size: cover;
    }
    .willy-wonka-slots {
      background: url('../../images/landing/desktop/willy-wonka-slots/willy-wonka-slots-desktop-game-neutral.png')
        no-repeat;
      background-position: top;
      background-size: cover;
    }

    .csr-racing-2 {
      background: url('../../images/landing/desktop/csr-racing-2/csr-racing-2-desktop-game-neutral.png') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .woz-magic-match {
      background: url('../../images/landing/desktop/woz-magic-match/woz-magic-match-desktop-game-neutral.jpeg')
        no-repeat;
      background-position: top;
      background-size: cover;
    }

    .monster-legends {
      background: url('../../images/landing/desktop/monster-legends/monster-legends-desktop-game-neutral.jpg') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .farmville-2 {
      background: url('../../images/landing/desktop/farmville-2/farmville-2-desktop-game-neutral.jpg') no-repeat;
      background-position: top;
      background-size: cover;
    }
    .farmville-3 {
      background: url('../../images/landing/desktop/farmville-3/farmville-3-desktop-game-neutral.jpg') no-repeat;
      background-position: top;
      background-size: cover;
    }

    .text-container {
      @apply text-center w-full flex flex-col items-center px-2 absolute;
      top: 47%;
    }

    .appname {
      @apply font-outline;
    }

    .game-qr-text {
      @apply my-4 mx-auto font-outline;
      width: 90%;
    }

    .qr-wrapper {
      @apply relative;
    }

    .qr-code {
      @apply p-2 rounded-lg;
      background: #fff;
    }

    .qr-wrapper::before,
    .qr-wrapper::after,
    span::before,
    span::after {
      display: block;
      content: '';
      width: 35px;
      height: 35px;
      position: absolute;
    }

    .qr-wrapper::before {
      top: 12px;
      left: 12px;
      border-top: 3px solid #fff;
      border-left: 3px solid #fff;
      border-top-left-radius: 20px;
    }

    .qr-wrapper::after {
      top: 12px;
      right: 12px;
      border-top: 3px solid #fff;
      border-right: 3px solid #fff;
      border-top-right-radius: 20px;
    }

    .qr-frame::before {
      bottom: 12px;
      left: 12px;
      border-bottom: 3px solid #fff;
      border-left: 3px solid #fff;
      border-bottom-left-radius: 20px;
    }

    .qr-frame::after {
      bottom: 12px;
      right: 12px;
      border-bottom: 3px solid #fff;
      border-right: 3px solid #fff;
      border-bottom-right-radius: 20px;
    }
  }

  .desktop-home-footer-game-neutral {
    @apply text-center font-normal py-2 sticky font-helvetica text-cadet-blue;
    font-size: 14px;
    line-height: 24px;
    // top: 100vh;
  }
}
