#zynga-store {
  @apply mx-auto pt-12 min-h-full h-full max-w-full;
}

.game {
  @apply h-full min-h-full block;
}

#page-wrap {
  @apply h-full overflow-auto min-h-full block;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

#page-wrap::-webkit-scrollbar {
  display: none;
}

.header {
  @apply fixed flex h-12 bg-zynga-red top-0 left-0 right-0 font-lato;
  z-index: 1000;
}

.is-loading .header {
  @apply text-gray-300;
}

.zynga-logo-wrapper {
  @apply w-20 h-7;

  //for safari image blur issue
  .zynga-logo {
    height: 400%;
    min-width: 400%;
    vertical-align: middle;
    transform: scale(0.25);
    transform-origin: 0 0;
  }
}

.force-hide {
  display: none !important;
}
