.punch-card-wrapper {
  @apply bg-black rounded-md mb-4 max-w-sm mx-auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  .quantity-amount-wrapper,
  p {
    @apply font-bebasNeue;
    font-size: 20px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.8);
  }

  .quantity-amount-wrapper {
    @apply flex flex-wrap items-center justify-center border border-black rounded-md px-1 pt-3 pb-2;
    background: linear-gradient(180deg, #4c0512 0%, #2f030b 100%);
    -moz-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);

    .filled-icon {
      @apply w-8;
      margin: 0 0.15rem;
    }

    .quantity {
      @apply font-normal flex items-center justify-center mb-1;
      padding: 0.85rem 0.75rem 0.5rem 0.75rem;
      clip-path: polygon(50% 12%, 100% 35%, 100% 79%, 50% 98%, 0% 79%, 0% 35%);
      background: rgba(0, 0, 0, 0.8);
      margin-right: 5px;
    }
    // TODO - apply CSS
    .complete {
      background: #f1a44a;
    }

    .amount {
      @apply flex items-center justify-start text-buff ml-2;
      font-size: 26px;
      line-height: 24px;
      word-break: break-word;

      .reward-img {
        @apply ml-1;
      }
    }
  }

  .message-wrapper {
    @apply bg-black text-green-menthol font-lato font-bold flex justify-center items-center py-1 rounded-md;
    font-size: 14px;
    line-height: 24px;
  }
}
