.game-poker {
  .player-details-wrapper {
    @apply border border-black rounded-md flex px-2 py-3;
    background: linear-gradient(180deg, #4c0512 0%, #2f030b 100%);
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    -moz-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    -webkit-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
    box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
  }
  .player-details-inner-wrapper {
    @apply flex items-center;
  }

  .player-id-avatar-wrapper {
    @apply flex flex-col items-center;
    flex: 2;
  }

  .player-details-wrapper .avatar {
    @apply rounded-full;
    width: 90px;
  }

  .player-id {
    @apply block px-2 py-0.5 rounded-sm mt-1.5 font-lato text-center;
    background-color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    line-height: 14px;
  }

  .player-details-title {
    @apply hidden;
  }

  .player-name-wrapper {
    @apply items-start ml-6;
    word-break: break-word;
    flex: 3;
  }

  .player-name {
    @apply font-normal;
    font-size: 26px;
    line-height: 32px;

    .name {
      @apply items-end;
    }
  }

  .comma {
    @apply contents;
  }

  .promotional-text {
    @apply block font-bold;
    font-size: 26px;
    line-height: 28px;
  }

  .get-more-text {
    color: #f1a44a;
  }

  .player-account {
    @apply hidden;
  }
}
