.game-e-and-p {
  .player-details-wrapper {
    @apply justify-center;
    &.has-avatar {
      @apply p-2;
      background: #4082c9;
      box-shadow: inset 0px 3px 3px rgba(0, 0, 0, 0.35);
      border-radius: 6px;
      .player-details-title {
        @apply hidden;
      }
      .player-name {
        @apply mb-3;
      }
    }
    .avatar {
      @apply mr-3;
      width: 140px;
      max-height: 100px;
    }
  }
  .player-details-inner-wrapper {
    @apply flex;
  }
  .player-details-title {
    @apply text-center font-normal font-outline;
    font-size: 22px;
    line-height: 25px;
  }

  .player-name {
    @apply text-center font-normal font-outline;
    font-size: 22px;
    line-height: 25px;
    // width: 85%;
    // margin: auto;
  }

  .player-account {
    @apply font-helvetica opacity-80 text-sm text-center;
  }

  .player-name-wrapper {
    @apply justify-center;
  }
}
