.game-poker {
  .balance-wrapper {
    @apply py-2.5 px-4;
    font-size: 18px;
    line-height: 21px;
  }

  .balance-title {
    @apply uppercase;
    flex: 1;
  }

  .individual-balances-wrapper {
    flex: 2;
  }
}
