.game-e-and-p {
  .landing {
    @apply max-w-sm mx-auto;
    background: url('../../images/landing/mobile/e-and-p/ep-hero.png') no-repeat;
    background-position-y: 28px;
    background-position-x: 125px;
    background-size: auto 255px;

    .item-inner {
      @apply px-6 py-0;
    }

    .item-name {
      @apply mt-4;
    }

    .item-image {
      max-width: 6rem;
    }
  }

  .landing-wrapper {
    background: url('../../images/logo/ep-logo.png') no-repeat;
    @apply pt-24;
    background-size: auto 70px;
    background-position: 25px 8px;
  }

  .landing-title {
    @apply hidden;
  }

  .landing-message {
    @apply w-2/4 font-normal font-outline pl-2;
    font-size: 20px;
    line-height: 24px;
  }

  /* prettier-ignore */
  .landing-button-wrapper{
    .landing-button{
      @apply max-w-sm;
    }

   .btn-inner {
    @apply font-normal;
    font-size: 22px;
    line-height: 22px;
  }
}
}
