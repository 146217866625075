.game-hir {
  .btn {
    @apply rounded max-w-sm font-signika font-bold;
    border-radius: 8px;
    min-width: 5rem;
    font-size: 18px;
    line-height: 28px;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.64));
  }

  .btn-sm {
    @apply bg-green-700 border-black font-bold py-2;
    font-size: 16px;
    line-height: 20px;
  }

  .btn-lg {
    @apply border-black font-bold py-2;

    .btn-inner {
      @apply font-bold;
      font-size: 20px;
      line-height: 25px;
    }
  }



  .primary {
    background: linear-gradient(to bottom, #afe151 45.38%, #169a0e 49.01%);
    box-shadow: -1px 0px 1px #84ff00 inset, 0px -3px 3px #84ff00 inset, 1px 0px 1px #84ff00 inset,
      0px 3px 2px #ffffff inset;

    .btn-inner {
      @apply green-font-outline px-2 text-center;
    }
  }

  .secondary {
    background: linear-gradient(to bottom, #f91ae7 46.87%, #96069b 50.28%);
    box-shadow: -3px 0px 3px #f91ae7 inset, 0px -3px 3px #f91ae7 inset, 3px 0px 3px #f91ae7 inset,
      0px 3px 3px #f91ae7 inset;

    .btn-inner {
      @apply purple-font-outline;
    }
  }

  .btn-inner {
    @apply font-bold;
    line-height: 20px;
  }

  button.btn-cancel {
    background: linear-gradient(180deg, rgba(216, 216, 216, 0.3) 45.38%, rgba(118, 118, 118, 0.3) 49.01%);
    box-shadow: inset 0px -1px 2px #aeaeae;

    .btn-inner {
      @apply purple-font-outline;
    }
  }
}