.hide-spinner.loading-spinner {
  opacity: 0;
  pointer-events: none;
}

.loading-spinner {
  @apply top-12 opacity-100 flex items-center justify-center fixed right-0 left-0 bottom-0;
  --spinner: #999;
  transition: opacity 0.25s;
  color: #999;
  z-index: 900;
}

.z-spinner {
  margin: 100px auto;
  width: 80px;
  height: 80px;
  position: relative;
  opacity: 0.5;
}

.z-spinner div {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.z-spinner div:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: var(--spinner);
  border-radius: 100%;
  -webkit-animation: segFadeDelay 1.2s infinite ease-in-out both;
  animation: segFadeDelay 1.2s infinite ease-in-out both;
}

.z-spinner .seg-2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.z-spinner .seg-3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.z-spinner .seg-4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.z-spinner .seg-5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.z-spinner .seg-6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.z-spinner .seg-7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.z-spinner .seg-8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.z-spinner .seg-9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.z-spinner .seg-10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.z-spinner .seg-11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.z-spinner .seg-12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.z-spinner .seg-2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.z-spinner .seg-3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.z-spinner .seg-4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.z-spinner .seg-5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.z-spinner .seg-6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.z-spinner .seg-7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.z-spinner .seg-8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.z-spinner .seg-9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.z-spinner .seg-10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.z-spinner .seg-11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.z-spinner .seg-12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes segFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

@keyframes segFadeDelay {

  0%,
  39%,
  100% {
    opacity: 0;
  }

  40% {
    opacity: 1;
  }
}

/* Paypal Loader */
.pp-spinner {
  padding: 18px;
  position: relative;
  text-align: center;
}

.pp-spinner:before {
  content: '';
  height: 30px;
  width: 30px;
  margin: -15px auto auto -15px;
  position: absolute;
  top: 50%;
  left: 50%;
  border-width: 8px;
  border-style: solid;
  border-color: #2180c0 #ccc #ccc;
  border-radius: 100%;
  animation: rotation 0.7s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.local-spinner.loading-spinner {
  position: absolute !important;
  @apply top-0 left-0 bottom-0 right-0 rounded-md;
  background: #fff !important;
  z-index: 10;

  .z-spinner {
    transform: scale(0.33);
    opacity: 1;
  }
}