.game-woz-slots {
  //Desktop

  .desktop-home-wrapper {
    background: url('../../images/landing/desktop/woz-slots/woz-slots-desktop-banner.png') no-repeat center center fixed;
    background-position-y: 40px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;

    .desktop-home-content {
      .game-logo {
        background: url('../../images/landing/desktop/woz-slots/woz-slots-desktop-logo.png') no-repeat;
        height: 230px;
        background-size: auto 230px;
      }

      .game-qr-text {
        font-size: 28px !important;
        line-height: 40px !important;
      }

      .qr-screen {
        height: 570px;
      }
    }
  }
}
