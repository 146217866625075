.game-hir {
  .balance-wrapper {
    background: #300049;
    box-shadow: inset -2px -4px 16px #96069b, inset 2px 4px 16px #96069b;
    border-top-width: 1px;
    border-bottom-width: 1px;
    border-style: solid;
    border-image: linear-gradient(90deg, #b6935d 0%, #ddc278 40.66%, #f0da85 60.83%, #8a6d33 100%) 30;
  }

  .balance-title {
    @apply font-bold mr-4;
    line-height: 20px;
  }

  .individual-balances-wrapper {
    @apply relative;
  }

  .individual-balances {
    margin-right: 0;
    background: #300049;
    box-shadow: inset 0px 9px 16px #000000;
    border-radius: 35px;

    .currency-icon {
      @apply absolute;
      left: -8px;
      height: 38px;
      width: 32px;
      bottom: -5px;
    }

    .current-amount {
      @apply px-6;
      font-size: 20px;
      line-height: 24px;

      &.compact {
        font-size: 18px;
      }
    }
  }
}