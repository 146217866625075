.game-e-and-p {
  .balance-wrapper {
    @apply items-center justify-center;
    font-size: 14px;
    line-height: 21px;
  }

  .in-game-balance {
    @apply justify-center;
  }
  .balance-title {
    @apply mr-9 uppercase;
  }

  .individual-balances-wrapper {
    @apply flex items-center mr-5 justify-around;
  }

  .individual-balances {
    @apply flex items-center;
  }

  .current-amount {
    @apply ml-1;
  }
}
