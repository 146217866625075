.game-poker {
  .btn {
    @apply font-normal;
    border-radius: 2px;
    min-width: 5rem;
    font-size: 20px;
    line-height: 24px;
  }

  .btn-sm .btn-extra {
    @apply hidden;
  }

  .btn-inner {
    @apply px-2;
  }




  .btn-extra {
    @apply absolute;
    background-color: rgba(255, 255, 255, 0.15);
    height: 120%;
    width: 108%;
    border-radius: 40%;
    top: 60%;
    left: -4%;
    transform: translate(0, -81%);
  }

  .btn:disabled {
    @apply opacity-70;
  }

  .primary {
    @apply text-black;
    background: linear-gradient(#f4e0a5 0%,
        #f3dea3 15.63%,
        #ead680 20.83%,
        #f4c95b 89.57%,
        #f3c24d 89.58%,
        #f1b93b 100%);
  }

  .secondary {
    @apply font-normal text-white;
    background: linear-gradient(to right, #42e06b 0%, #21b387 100%);
    border-radius: 6px;
  }

  .secondary .btn-inner {
    filter: drop-shadow(1px 1px rgba(0, 0, 0, 0.25));
    font-size: 22px;
  }

  .btn-lg {
    @apply max-w-sm;
  }

  .btn-cancel {
    @apply max-w-sm;
    border-radius: 6px;
    font-size: 24px;
    line-height: 28px;
  }

  .btn-cancel .btn-inner {
    filter: drop-shadow(1px 1px rgba(0, 0, 0, 0.5));
    font-size: 22px;
  }

  button.btn-cancel .btn-extra {
    background: #bbb;
  }

  button.btn-submit {
    @apply max-w-sm;
  }
}