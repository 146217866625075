.balance-wrapper {
  @apply py-2.5 px-4;
  background: rgba(0, 0, 0, 0.5);
}

.balance-title {
  @apply flex items-center;
}

.individual-balances-wrapper {
  @apply flex items-center flex-wrap break-all;
}

.individual-balances {
  @apply flex items-end mr-4;
}

.current-amount {
  @apply ml-1;
}
