.game-e-and-p {

  .btn {
    @apply font-normal border rounded;
    border-radius: 5px;
    min-width: 7rem;
    font-size: 18px;
    line-height: 28px;
    background: linear-gradient(180deg, #83d745 14.7%, #aef05b 14.71%, #85d947 14.72%, #54a02f 62.32%, #569f31 74.32%);
    border: 1px solid #000000;
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 3px 0px rgba(0, 0, 0, 0.25);
  }

  .btn-sm {
    @apply bg-green-700 border-black;
  }

  .btn-extra {
    background: #8edf4e;
    border-radius: 3px;
    @apply absolute;
    bottom: 40%;
    top: 5px;
    left: 5px;
    right: 5px;
  }

  .btn-inner {
    @apply btn-outline;
  }

  button.btn-cancel {
    background: linear-gradient(180deg, #d1d1d1 14.7%, #ececeb 14.71%, #d6d6d6 14.72%, #a3a3a3 62.32%, #9c9c9c 74.32%);
  }

  button.btn-cancel .btn-extra {
    background: #dadada;
  }


}