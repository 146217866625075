/*  Notification Banner CSS */
.notification-banner-container {
  background: #dfa749;
  height: 90px;
  color: #222c37;
}
.notification-banner-container p {
  font-size: 14px;
}
.notification-banner-container a {
  white-space: nowrap;
}
.notification-banner-container + .header {
  top: 90px;
}
.notification-banner-container ~ #page-wrap {
  padding-top: 90px;
}

@media screen and (min-width: 368px) {
  .notification-banner-container {
    height: 80px;
  }
  .notification-banner-container p {
    font-size: 14px;
  }
  .notification-banner-container + .header {
    top: 80px;
  }
  .notification-banner-container ~ #page-wrap {
    padding-top: 80px;
  }
}
@media screen and (min-width: 500px) {
  .notification-banner-container {
    height: 60px;
  }
  .notification-banner-container + .header {
    top: 60px;
  }
  .notification-banner-container ~ #page-wrap {
    padding-top: 60px;
  }
}
@media screen and (min-width: 980px) {
  .notification-banner-container {
    height: 45px;
  }
  .notification-banner-container + .header {
    top: 45px;
  }
  .notification-banner-container ~ #page-wrap {
    padding-top: 45px;
  }
}
