.game-hir {
  .player-details-wrapper {
    @apply rounded-md flex px-0.5 py-0.5;
    @apply shadow-inner rounded-lg bg-gradient-to-r from-buff-mid via-shingle-fawn to-buff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }
  .player-details-inner-wrapper {
    @apply rounded-md flex px-0.5 py-2;
    flex: 1;
    background: linear-gradient(transparent, #000 100%),
      url('../../images/hit-it-rich/hir-player-details-bg.png') no-repeat;
    background-position: center;
  }
  .player-id-avatar-wrapper {
    @apply flex flex-col items-center justify-center;
    flex: 2;
  }
  .player-details-wrapper .avatar {
    @apply rounded-full;
    width: 90px;
    background-clip: padding-box;
    border: 2px solid transparent;
    background: linear-gradient(90deg, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);
  }

  .player-id {
    @apply block rounded-sm mt-1.5 font-lato text-center bg-gradient-to-r from-buff-mid via-shingle-fawn to-buff;
    padding: 1px;
  }

  .player-id-inner {
    @apply py-0.5 px-2;
    background-color: #1c0226;
    font-size: 12px;
    line-height: 14px;
  }
  .player-details-title {
    @apply hidden;
  }

  .player-name-wrapper {
    @apply items-start ml-6 purple-font-outline;
    flex: 3;
  }

  .player-name {
    font-size: 24px;
    line-height: 30px;
  }

  .comma {
    @apply hidden;
  }

  .promotional-text {
    @apply block font-bold mt-1 purple-font-outline;
    font-size: 20px;
    line-height: 22px;
  }

  .get-more-text {
    @apply text-rubber-ducky-yellow;
  }

  .player-account {
    @apply hidden;
  }
}
