.catalog {
  @apply h-full min-h-full block px-4;
}
.catalog h2 {
  @apply text-lg text-center font-bold;
}

.items-list {
  @apply mt-6;
}

.item-outer {
  @apply relative border rounded-lg border-white bg-white mx-auto;
}

.product-name {
  @apply hidden;
}

.catalog-tabs-wrapper {
  @apply hidden;
}

.product-content-items-wrapper {
  @apply hidden;
}

.item-help-mark {
  @apply hidden;
}

.back-card {
  @apply hidden;
}
