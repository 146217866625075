.game-hir {
  //Desktop

  .desktop-home-wrapper {
    background: url('../../images/landing/desktop/hit-it-rich/hir-banner.jpeg') no-repeat center center fixed;
    // background-size: 100% 100%;
    background-position: top;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    // background-position-y: -40px;

    .desktop-home-content {
      // height: calc(100vh - 3rem); //Header
      .game-logo {
        background: url('../../images/landing/desktop/hit-it-rich/hir-desktop-logo.png') no-repeat;
        height: 300px;
        background-size: auto 300px;
        background-position: -0.4rem;
      }

      .qr-screen-wrapper {
        @apply ml-5;
      }
      .qr-screen {
        height: 570px;
      }
    }
    .game-neutral {
      .game-qr-text {
        font-size: 20px;
        line-height: 28px;
      }
    }
    .game-qr-text {
      font-size: 28px;
      line-height: 40px;
    }
  }

  .home {
    background: url('../../images/hit-it-rich/hir-home-bg.png') no-repeat;
    background-size: 100%;

    .message-container {
      @apply border-0;
      padding: 1px;
      background: linear-gradient(to right, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);

      .message-outer {
        @apply rounded-lg;
        background: linear-gradient(#79007d 20%, #300049 100%);
      }
      .message-inner {
        @apply relative flex items-center justify-center w-full border-0;
        background: linear-gradient(#79007d 20%, #300049 100%);
      }

      .message-inner::before {
        @apply absolute inset-0;
        content: '';
        background-image: url('../../images/hit-it-rich/hir-alert-mask.png');
        background-size: cover;
        position: absolute;
        opacity: 0.5;
      }

      .message {
        @apply text-center relative;
        margin: auto;
        max-width: 80%;
        font-size: 20px;
        line-height: 25px;
      }
    }

    @media (min-width: 768px) {
      .home-wrapper {
        padding-top: 550px;
      }
    }
    @media (min-width: 800px) {
      .home-wrapper {
        padding-top: 650px;
      }
    }
    @media (min-width: 908px) {
      .home-wrapper {
        padding-top: 730px;
      }
    }

    .browser-hint {
      .message-inner {
        h3 {
          font-size: 20px;
          line-height: 24px;
        }
        p,
        ol {
          @apply font-openSans;
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}
