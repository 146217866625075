.game-hir {
  .receipt {
    @apply p-0;
    background: url('../../images/hir-receipt-bg.png') no-repeat;
    background-size: cover;
    height: 100%;
    background-position: 0px -20px;
  }

  .receipt-image-wrapper {
    @apply hidden;
  }

  .purchase-complete {
    @apply pt-16 font-signika font-bold;
    font-size: 32px;
    line-height: 40px;
  }

  .gems-purchased {
    @apply font-openSans font-semibold;
    font-size: 20px;
    line-height: 26px;
  }

  .additional-purchase {
    @apply flex items-center justify-center px-4 my-4 max-w-sm mx-auto;

    :not(:last-child, .item-image-wrapper) {
      @apply mr-2;
    }

    .item-image-wrapper {
      @apply w-12 mr-1;

      img {
        min-width: 30px;
      }
    }

    .item-name {
      @apply font-semibold text-sm font-openSans text-left text-white;
      line-height: 19px;
    }
  }

  .three-items-wrapper {
    .additional-item {
      width: 33%;
      @apply pb-2;
    }
  }

  .multi-line-wrapper {
    flex-wrap: wrap;

    .additional-item {
      width: 40%;
      @apply pb-2;
    }
  }

  .purchase-detail {
    @apply font-semibold font-openSans;
    font-size: 12px;
    line-height: 20px;
  }

  .receipt-content-wrapper {
    @apply px-4;
  }

  .purchase-details-wrapper {
    @apply rounded-md flex px-0.5 py-0.5 mx-4;
    @apply shadow-inner rounded-lg bg-gradient-to-r from-buff-mid via-shingle-fawn to-buff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .purchase-details-wrapper-inner {
    @apply rounded-md flex flex-col p-2 font-openSans;
    flex: 1;
    background: #1c0226;
  }

  .separator {
    @apply my-2;
    opacity: 0.2;
    border: 1px solid #ffffff;
  }

  .receipt-outer {
    @apply rounded-t-lg rounded-b-none mt-4 mb-6 mx-4 relative border-none bg-white;
  }

  .receipt-label {
    @apply font-openSans font-bold text-black;
    font-size: 12px;
  }

  .receipt-value {
    @apply font-semibold;
    color: rgba(0, 0, 0, 0.8);
  }

  .receipt-outer-border {
    @apply block max-w-sm mx-auto absolute;
    bottom: -10px;
    left: 0;
    right: 0;

    &:before {
      background: #fff;
      content: '';
      height: 10px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      clip-path: polygon(0 0,
          2.5% 100%,
          5% 0,
          7.5% 100%,
          10% 0,
          12.5% 100%,
          15% 0,
          17.5% 100%,
          20% 0,
          22.5% 100%,
          25% 0,
          27.5% 100%,
          30% 0,
          32.5% 100%,
          35% 0,
          37.5% 100%,
          40% 0,
          42.5% 100%,
          45% 0,
          47.5% 100%,
          50% 0,
          52.5% 100%,
          55% 0,
          57.5% 100%,
          60% 0,
          62.5% 100%,
          65% 0,
          67.5% 100%,
          70% 0,
          72.5% 100%,
          75% 0,
          77.5% 100%,
          80% 0,
          82.5% 100%,
          85% 0,
          87.5% 100%,
          90% 0,
          92.5% 100%,
          95% 0,
          97.5% 100%,
          100% 0);
    }
  }

  .cta-wrapper {
    @apply px-4;
  }

  .customer-support {
    @apply hidden;
  }

  @media (min-width: 412px) {

    .purchase-details-wrapper,
    .receipt-outer {
      margin-left: auto;
      margin-right: auto;
    }
  }
}