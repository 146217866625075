.game-hir {
  .cart {
    @apply pt-4;

    .marketing-msg {
      @apply hidden;
    }
  }

  .cart-item {
    @apply rounded-md flex border-0;
    @apply shadow-inner rounded-lg bg-gradient-to-r;
    background: linear-gradient(to right, #f0da85, #7c5c36, #f0da85, #7c5c36, #f0da85);
    padding: 1.5px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .cart-item-inner {
    @apply rounded-md flex flex-col px-4 py-3;
    flex: 1;
    background: linear-gradient(transparent, #000 100%),
      url('../../images/hit-it-rich/hir-player-details-bg.png') no-repeat;
    background-position: center;
  }

  .cart-title {
    @apply text-2xl font-bold;
    text-shadow: none;
  }

  .cart-image-wrapper,
  .cart-description {
    @apply hidden;
  }

  .btn.cc {
    @apply drop-shadow-none filter-none;
  }

  .credit-card-form {
    @apply max-w-sm mx-auto;

    .cc-form {
      input::placeholder {
        color: rgba(255, 255, 255, 0.6);
      }

      input {
        background-color: #1c0226;
        color: #fff;
      }

      input:-webkit-autofill,
      input:-webkit-autofill:hover,
      input:-webkit-autofill:focus,
      input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #1c0226 inset !important;
        border-color: rgba(255, 255, 255, 0.4);
        -webkit-text-fill-color: #fff !important;
      }

      .ElementsApp input:-webkit-autofill {
        -webkit-text-fill-color: #fff !important;
      }

      .fa-icon {
        color: rgba(255, 255, 255, 0.6);
      }
    }

    .stripe-field {
      background-color: #1c0226;
    }

    .credit-card-field {
      background-color: #1c0226;
      border-color: rgba(255, 255, 255, 0.4);
    }

    .border-card-border,
    .stripe-field,
    .cc-form input {
      border-color: rgba(255, 255, 255, 0.4);
    }
  }

  .cart-details {
    @apply flex items-center justify-between w-full m-0;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);

    .cart-name {
      @apply font-bold text-xl;
    }

    .cart-price {
      @apply font-bold text-xl;
    }
  }

  .cart-item-contents-wrapper {
    @apply block mt-2;

    .content-item {
      @apply flex items-center mt-2;
    }

    .content-item-image-wrapper {
      @apply mr-2;

      img {
        width: 25px;
      }
    }

    .content-item-name {
      @apply font-semibold text-sm font-openSans;
    }
  }

  .breaker:before,
  .breaker:after {
    @apply border-0;
    height: 1px;
    background: linear-gradient(90deg, #b6935d 0%, #ddc278 40.66%, #f0da85 60.83%, #8a6d33 100%);
  }

  .breaker:before {
    @apply ml-0;
  }

  .breaker:after {
    @apply mr-0;
  }

  .customer-support {
    @apply font-openSans;

    a {
      color: #20b420;
    }
  }

  .paypal-container {
    @apply max-w-sm;
  }

  .paypal-container .pp-button {
    @apply max-w-sm;
    font-size: 20px;
    line-height: 25px;
  }
}