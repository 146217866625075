.game-e-and-p {
  .catalog {
    background: url('../../images/logo/ep-logo.png') no-repeat;
    background-position: top;
    background-size: auto 70px;
    background-position-y: 15px;
    padding-top: 100px;
    &.hide-logo {
      background: none;
      padding-top: 25px;
    }
  }

  .catalog-subtitle {
    @apply text-center font-lalazar mt-6 px-12;
    font-size: 18px;
    line-height: 21px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
  }

  .item-outer {
    @apply my-6 p-px bg-white bg-gradient-to-b from-gray-200 to-gray-400 shadow-sm border-0;
    max-width: 24rem;
  }

  .item-inner {
    @apply border-0 shadow-inner  border rounded-lg border-white bg-gradient-to-t from-gray-400 to-blue-800 h-full flex items-center px-6 py-1.5;
    background: linear-gradient(180deg, #1c4b6a 0%, #398aaf 100%);
  }

  .item-image {
    @apply inline;
    max-width: 5rem;
  }

  .item-description {
    @apply text-sm;
  }

  .item-button-wrapper {
    /* @apply pb-2; */
  }

  .credit-card-form {
    @apply max-w-xs mx-auto;
  }

  .item-image-wrapper {
    @apply flex items-center justify-center top-4 bottom-2 left-2 w-1/3;
    max-width: 6rem;
  }

  .item-name {
    @apply font-normal mb-1 font-outline text-lg font-normal break-words;
    margin-top: 10px;
    font-size: 24px;
    line-height: 24px;
  }

  .item-details {
    @apply flex flex-col justify-center text-center ml-4 mb-2 w-2/3;
  }

  .item-description {
    @apply hidden;
  }
}
