.game-poker {
  .home {
    background: url('../../images/landing/mobile/poker/poker-home-hero.png') no-repeat;
    background-position: top center;
    background-position-y: 25px;
    background-size: auto 300px;

    .home-wrapper {
      background: url('../../images/logo/poker-logo.png') no-repeat;
      background-size: auto 120px;
      background-position: center 210px;
    }

    .message-container {
      @apply bg-white bg-gradient-to-b from-gray-200 to-gray-400 shadow-sm border-0;
      padding: 0.5px;

      .message-inner {
        @apply border-0 shadow-inner;
        background: linear-gradient(180deg, #f2a44a 0%, #cd6e44 100%);
      }

      .message {
        @apply font-normal text-center font-lato font-bold text-black;
        margin: auto;
        max-width: 74%;
        font-size: 20px;
        line-height: 24px;
      }
    }

    .browser-hint-btn-text {
      @apply py-1;
      min-width: 9rem;
      font-weight: 700 !important;
      font-family: 'Lato-Regular' !important;
      font-size: 20px;
      line-height: 24px;
    }

    .browser-hint {
      .message-inner {
        @apply font-lato text-black;
        h3 {
          @apply font-bold text-black;
          font-size: 22px;
          line-height: 24px;
        }
        p {
          @apply font-bold;
          font-size: 16px;
          line-height: 24px;
        }
        ol {
          @apply font-normal;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  //Desktop

  .desktop-home-wrapper {
    background: url('../../images/landing/desktop/poker/desktop-poker-banner.png') no-repeat center center fixed;
    background-position-y: 40px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    .desktop-home-content {
      .game-logo {
        background: url('../../images/landing/desktop/poker/poker-desktop-logo.png') no-repeat;
        height: 160px;
        background-size: auto 160px;
      }

      .qr-screen {
        height: 570px;
      }
    }
  }
}
