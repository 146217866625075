.game-e-and-p {
  .cart-item {
    @apply bg-white bg-gradient-to-b from-gray-200 to-gray-400 shadow-sm border-0;
    padding: 3px;
  }

  .cart-item-inner {
    @apply shadow-inner pt-1.5 border-0 rounded-lg border-white bg-gradient-to-t from-gray-400 to-blue-800 h-full flex justify-between items-center px-1.5;
    background: linear-gradient(180deg, #1c4b6a 0%, #398aaf 100%);
    min-height: 80px;
  }

  .cart-description {
    @apply hidden;
  }

  .cart-details {
    @apply flex w-full h-full justify-between min-h-full font-normal;
    font-size: 18px;
    line-height: 18px;
  }

  .cart .cart-image-wrapper {
    @apply w-20;
  }

  .cart-name {
    @apply place-self-start inline-block font-normal;
    font-size: 18px;
    line-height: 18px;
  }

  .cart-price {
    @apply m-0 inline-block mr-2 font-normal;
    font-size: 18px;
    line-height: 18px;
  }

  .stripe-vendor-outer {
    max-width: 20rem;
  }

  .button-loading {
    max-width: 20rem;
  }

  .btn.cc {
    @apply filter-none;
    box-shadow: none;
    text-shadow: none;
    box-shadow: none;
    background: none;
    max-height: 50px;
    background-color: #0073B8;
    border-radius: 5px;

    .btn-inner {
      text-shadow: none;
    }

    .btn-extra {
      display: none;
    }
  }

  .credit-cart-text {
    @apply font-normal font-outline text-lg;
  }
}