.game-hir {
  .footer {
    @apply font-semibold font-openSans;
    color: #ffffff;
    font-size: 12px;
    line-height: 16px;
  }
  .desktop-home-footer,
  .footer {
    .exclaimation {
      display: inline;
    }
  }

  .landing {
    .footer {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
