.game-poker {
  .receipt {
    @apply p-0;
    height: 100%;
    background: #2a0101;
    .receipt-image-wrapper {
      @apply hidden;
    }
    .receipt-content-wrapper {
      @apply px-4;
    }

    .purchase-complete {
      @apply flex justify-center items-end h-36 font-outline;
      background: url('../../images/poker/poker-receipt-bg.png') no-repeat;
      background-size: cover;
      background-position-y: -50px;
      font-size: 36px;
    }
    .gems-purchased {
      @apply font-lato;
      line-height: 26px;
    }

    .additional-purchase {
      @apply text-sm font-helvetica;
      line-height: 23px;
      color: #f2a44a;
      margin-top: 2px;
    }

    .purchase-details-wrapper {
      @apply border-2 border-black rounded-md block px-3 py-2 mx-4;
      background: linear-gradient(180deg, #4c0512 0%, #2f030b 100%);
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      -moz-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
      -webkit-box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
      box-shadow: inset 4px 4px 2px rgba(0, 0, 0, 0.25);
      font-family: 'Helvetica Neue';
    }
    .purchase-detail {
      @apply my-1;
      font-size: 13px;
      line-height: 23px;
    }

    .separator {
      opacity: 0.4;
      border: 1px solid #ffffff;
    }

    .receipt-outer {
      @apply mt-2 mx-4;
    }

    .customer-support {
      @apply hidden;
    }

    .shop-agian.primary {
      @apply font-normal text-white;
      background: linear-gradient(to right, #42e06b 0%, #21b387 100%);
      border-radius: 6px;
    }

    .cta-wrapper {
      @apply px-4;
    }

    @media (min-width: 412px) {
      .purchase-details-wrapper,
      .receipt-outer {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}
