@media only screen and (max-width: 380px) {
  .game-poker {
    .landing {
      .landing-title {
        @apply pl-2;
      }

      .landing-message {
        @apply pl-2 text-left;
      }
    }
  }
}

.game-poker {
  .landing {
    @apply max-w-sm mx-auto;
    background: url('../../images/landing/mobile/poker/poker-hero.png') no-repeat;
    background-size: auto 296px;
    background-position: 170px 12px;

    .item-outer {
      @apply my-6 py-2 pr-2 bg-white bg-gradient-to-b from-gray-200 to-gray-400 shadow-sm border-0;
      padding: 0.5px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
      max-width: 20rem;
    }

    .item-outer:last-of-type {
      @apply mb-2;
    }

    .landing-button-wrapper {
      @apply mt-2;
    }

    .free-coin {
      @apply block text-center;
      font-size: 20px;
    }

    .item-inner {
      background: linear-gradient(180deg, #f2a44a 0%, #cd6e44 100%);
    }

    .item-name {
      @apply hidden;
    }

    .item-image {
      max-width: 4.5rem;
    }

    .front-card {
      @apply px-3.5;
    }

    .item-description {
      @apply flex font-normal;
      font-size: 16px;
      line-height: 20px;
    }

    .item-image-wrapper {
      @apply w-4/12;
    }

    .item-details {
      @apply flex flex-row items-center w-9/12 mr-0 ml-0.5;
    }

    .product-content-items-wrapper {
      @apply hidden;
    }

    .item-help-mark {
      @apply hidden;
    }

    .product-name {
      @apply flex;
      font-size: 26px;
      line-height: 31px;
    }
  }

  .landing-wrapper {
    font-size: 30px;
    line-height: 24px;
  }

  .landing-title {
    @apply pl-4;
    font-size: 30px;
    line-height: 24px;
  }

  .landing-message {
    @apply font-normal font-lato pt-2 pl-0;
    font-size: 18px;
    line-height: 22px;
    color: #f2a34a;
    width: 55%;
  }

  .landing-button-wrapper .btn-inner {
    @apply font-normal text-white;
    font-size: 22px;
    line-height: 26px;
  }

  .landing-button {
    @apply max-w-xs;
  }
}